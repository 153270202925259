import React, { Fragment } from "react";

import ScreenableArea from "./components/ScreenableArea";
import Slide from "./components/Slide";
import WebcamSlide from "./components/WebcamSlide";
import Controls from "./components/Controls";

class App extends React.Component {
  state = {
    days: [],
    loading: "loadingNetwork",
    showWebcam: false,
    currentSlideIndex: 0,
    showCursor: false,
  };

  toggleWebcam = () => {
    this.setState({ showWebcam: !this.state.showWebcam });
  };

  toggleCursor = () => {
    this.setState({ showCursor: !this.state.showCursor });
  };

  incrementSlide = () => {
    if (this.state.currentSlideIndex + 1 >= this.state.days.length) return;
    this.setState({ currentSlideIndex: this.state.currentSlideIndex + 1 });
  };

  decrementSlide = () => {
    if (this.state.currentSlideIndex === 0) return;
    this.setState({ currentSlideIndex: this.state.currentSlideIndex - 1 });
  };

  keyPressed(el) {
    return (e) => {
      if (e.keyCode === 32 || e.keyCode === 39) {
        el.incrementSlide();
        e.preventDefault();
      }
      if (e.keyCode === 37) {
        el.decrementSlide();
        e.preventDefault();
      }
      if (e.keyCode === 87) {
        el.toggleWebcam();
        e.preventDefault();
      }

      if (e.keyCode === 67) {
        if (!this.state.showCursor) this.setState({ showCursor: true });
        e.preventDefault();
      }
    };
  }

  keyUp() {
    return (e) => {
      if (e.keyCode === 67) {
        this.setState({ showCursor: false });
        e.preventDefault();
      }
    };
  }

  async fetchData() {
    const res = await fetch(
      "https://www.meteo-biarritz.com/api/m/last-bulletin?map-limit=9"
      // 'http://localhost:3000/api/m/last-bulletin?map-limit=9'
    );
    res
      .json()
      .then((data) => {
        const days = data.maps;
        this.setState({ days: days });
        this.setState({ loading: "done" });

        days.forEach((day) => {
          const france = new Image();
          france.src = day.france;

          const europe = new Image();
          europe.src = day.europe;

          const temp = new Image();
          temp.src = day.temp;
        });
      })
      .catch((err) => this.setState({ loading: err }));
  }

  initCursor() {
    this.cursor = document.createElement("div");
    this.cursor.style.position = "absolute";
    this.cursor.style.margin = "0";
    this.cursor.style.padding = "15px";
    this.cursor.style.backgroundColor = "rgba(255,255,255,1)";
    // this.cursor.style.borderWidth = 1;
    // this.cursor.style.borderColor = 'rgba(255,255,255,0.7)';
    // this.cursor.style.borderStyle = 'solid';
    this.cursor.style.borderRadius = "50%";
    document.body.appendChild(this.cursor);
    document.body.onmousemove = this.updateCursorPosition;
  }

  updateCursorPosition = (e) => {
    var e = e || window.event;
    this.cursor.style.display = this.state.showCursor ? "block" : "none";
    this.cursor.style.left = e.clientX - 10 + "px";
    this.cursor.style.top = e.clientY - 10 + "px";
  };

  componentDidMount() {
    this.fetchData();
    document.addEventListener("keydown", this.keyPressed(this), false);
    document.addEventListener("keyup", this.keyUp(this), false);
    this.initCursor();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPressed(this), false);
  }

  showContent = () => {
    if (this.state.showWebcam === true) {
      return <WebcamSlide />;
    }
    return this.state.days.map((day, index) => (
      <Slide
        title={day.date}
        france={day.france}
        europe={day.europe}
        temp={day.temp}
        key={day.date}
        style={{
          opacity: index === this.state.currentSlideIndex ? "1" : "0",
        }}
      />
    ));
  };

  render() {
    return (
      <Fragment>
        <Controls
          previousButtonDisabled={this.state.currentSlideIndex === 0}
          nextButtonDisabled={
            this.state.currentSlideIndex + 1 >= this.state.days.length
          }
          onNextButtonPressed={this.incrementSlide}
          onPreviousButtonPressed={this.decrementSlide}
          onToggleWebcam={this.toggleWebcam}
          webcamShowed={this.state.showWebcam}
        />
        <ScreenableArea backgroundColor="#051F3E">
          {this.showContent()}
        </ScreenableArea>

        <Controls
          previousButtonDisabled={this.state.currentSlideIndex === 0}
          nextButtonDisabled={
            this.state.currentSlideIndex + 1 >= this.state.days.length
          }
          onNextButtonPressed={this.incrementSlide}
          onPreviousButtonPressed={this.decrementSlide}
          onToggleWebcam={this.toggleWebcam}
          webcamShowed={this.state.showWebcam}
        />
      </Fragment>
    );
  }
}

export default App;
