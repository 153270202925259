import React from 'react';

import styles from './Controls.module.scss';

export default ({
  previousButtonDisabled,
  nextButtonDisabled,
  onNextButtonPressed,
  onPreviousButtonPressed,
  onToggleWebcam,
  webcamShowed,
}) => {
  return (
    <div className={styles.wrapper}>
      <button
        className={styles.button}
        disabled={previousButtonDisabled}
        onClick={onPreviousButtonPressed}
      >
        Slide précédent
      </button>
      <button className={styles.button} onClick={onToggleWebcam}>
        {webcamShowed ? 'Masquer Webcam' : 'Afficher Webcam'}
      </button>
      <button
        className={styles.button}
        disabled={nextButtonDisabled}
        onClick={onNextButtonPressed}
      >
        Slide Suivant
      </button>
    </div>
  );
};
