import React from 'react';
import Webcam from 'react-webcam';

import styles from './WebcamSlide.module.scss';

export default ({ style }) => {
  const videoConstraints = {
    aspectRatio: 1.779,
  };
  return (
    <div style={style} className={styles.root}>
      <Webcam videoConstraints={videoConstraints} mirrored={true} />
    </div>
  );
};
