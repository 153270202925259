import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import styles from './Slide.module.scss';

moment.locale('fr');

export default ({ title, france, europe, temp, style }) => {
  return (
    <div style={style} className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{moment(title).format('dddd LL')}</div>
        <div className={`${styles.row} ${styles.france}`}>
          <img className={styles.img} src={france} />
          <img className={styles.img} src={temp} />
        </div>
        <div className={`${styles.row} ${styles.europe}`}>
          <img className={styles.img} src={europe} />
        </div>
      </div>
    </div>
  );
};
