import React from "react";

import styles from "./ScreenableArea.module.scss";

export default ({ backgroundColor, className, children }) => {
  const style = { backgroundColor };
  return (
    <div className={`${className} ${styles.wrapper}`}>
      <div className={styles["size-wrapper"]}>
        <div className={styles.ratio} style={style}>
          <div className={styles.content}>{children}</div>
          <div className={styles.wattermark}>
            <img src="/logo_villes.png" width="250" />
          </div>
        </div>
      </div>
    </div>
  );
};
